/* Define custom font */
@font-face {
    font-family: "Helvetica Neue";
    font-display: swap;  /* Improves text visibility while fonts are loading */
}

/* Header CSS */
.MainHeader-header {
    background-color: transparent;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    color: #E1DEDB;
    font-family: "Helvetica Neue"; /* Use the defined font-family name */
    letter-spacing: -3px;

}
