.contact-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full viewport height */
    background-color: black; /* Black background */
    overflow-y: hidden;

}

.email-link {
    color: #E1DEDB; /* White text */
    font-size: 48px; /* Large font size */
    text-decoration: none; /* No underline */
}

.email-link:hover {
    text-decoration: underline; /* Underline on hover */
    cursor: pointer;
}

@media (max-width: 768px) {
    .contact-container {
        padding: 0px;
    }

    .email-link {
        font-size: 20px;
        
    }
}
