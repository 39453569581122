.about {
    padding: 20px;
    margin: 20px;
    border-radius: 8px;
}

.about h1 {
    color: #E1DEDB;
    margin-top: 50px;
    letter-spacing: -3px;

}

.about p {
    line-height: 1.6;
    color: #E1DEDB;

    block-size: 15px;
    inline-size: 500px;
    font-family: "Helvetica Neue", sans-serif;


}

.about img {
    position: fixed;
    scale: 60%;
    margin-left: 36%;
    margin-top: -2%;
    opacity: 0.7;
}

@media (max-width: 768px) {
    .about p {
        inline-size: 100%; /* Ensure the text fits the screen */
        padding: 0 10px; /* Add padding to avoid text touching edges */
        font-size: 16px; /* Adjust font size for readability on mobile */
    }

    .about img {
        width: 80%; /* Make the image smaller on mobile */
        margin: 200px auto; /* Center the image */
        position: fixed; /* Remove fixed position on mobile */
    }
}