.clothing-page {
    margin: 100px auto; /* Increased top and bottom margin */

    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 items per row */
    gap: 20px; /* space between items */
    padding: 20px;
}

.clothing-item {
    font-family: "Helvetica Neue";
    padding: 10px;
    text-align: center;
    color: white; /* Text color changed to white */
}

.clothing-item img {
    width: 100%; /* Responsive image size */
    height: auto;
}

button {
    background-color: #19cc9a; /* Green */
    color: #E1DEDB;
    padding: 10px 20px;
    margin: 10px 0;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

/*
button:hover {
    background-color: #ff0000;
}
*/