.artist-card {
    flex: 0 1 30%;
    box-sizing: border-box;
    margin: 10px;
    width: calc(33.333% - 20px);
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(255, 255, 255, 0);
}

@media (max-width: 1024px) {
    .artist-card {
        width: calc(50% - 40px);
    }
}

@media (max-width: 768px) {
    .artist-card {
        width: calc(100% - 40px);
    }

    .media-gallery {
        width: 100%;  /* Make the gallery take the full width */
        height: auto;  /* Let the height adjust automatically */
        max-width: 350px; /* Maximum width for media on mobile */
        max-height: 350px;
        aspect-ratio: 1; /* Ensures the media keeps the correct aspect ratio */
    }

    .media-gallery img,
    .media-gallery video {
        width: 100%;
        height: auto;
        object-fit: contain;  /* Ensure the entire image or video fits within the container */
    }

    .artist-info {
        width: 100%;  /* Make sure the info takes the full width */
        max-width: 350px;  /* Same max width as the media */
        padding: 10px;  /* Add padding around the text */
        font-size: 18px; /* Smaller font size for mobile */
        text-align: left; /* Align the text to the left */
    }

    .artist-info h2 {
        font-size: 45px; /* Adjust artist name size */
        white-space: normal; /* Allow text to wrap */
        overflow: hidden; /* Prevent overflow issues */
        text-overflow: ellipsis; /* Add ellipsis if text overflows */
        margin: 0; /* Remove any default margins */
        padding: 0; /* Ensure no padding shifts the alignment */
    }

    .artist-info p {
        font-size: 18px; /* Smaller description text */
    }
}

@media (max-width: 500px) {
    .artist-info h2 {
        font-size: 30px; /* Further reduce artist name size */
    }

    .artist-info p {
        font-size: 16px; /* Smaller description text */
    }
}

.media-gallery {
    width: 500px;
    height: 500px;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.media-gallery img,
.media-gallery video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.media-gallery button {
    position: absolute;
    top: 50%; /* Align vertically in the middle */
    transform: translateY(-50%); /* Offset transform by half its height for perfect centering */
    z-index: 20; /* Higher z-index to ensure they are above the media */
    background-color: rgba(0, 0, 0, 0.0); /* Semi-transparent background */
    border: none;
    color: #E1DEDB;
    cursor: cell;
    font-size: 24px;
    padding: 0 20px;
}

.media-gallery button:first-child {
    left: 10px; /* Position the 'previous' button on the left */
}

.media-gallery button:last-child {
    right: 10px; /* Position the 'next' button on the right */
}


.artist-info {
    width: 500px;
    height: 150px; /* Set the height of the info box */
    padding: 0px;
    box-sizing: border-box; /* Include padding in height calculation */
    background: transparent;
    color: #E1DEDB;
    display: flex;
    font-size: 30px;

    font-family: "Helvetica Neue";
    letter-spacing: -3px;
}

.artist-link {
    color: #E1DEDB;
    text-decoration: none;
}

.artist-link:hover {
    text-decoration: underline;
    color: #ff0000;
}

@media (max-width: 480px) {
    .artist-info {
        letter-spacing: -1px;
    }
}

