.artist-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    /*gap: 30px; */
    margin: 50px auto; /* Increased top and bottom margin */
    padding: 100px 20px; /* More padding at the top */
    max-width: 1620px;

}

@media (max-width: 768px) {
    .artist-list {
        padding: 50px 20px; /* Less padding at the top */
    }
}

