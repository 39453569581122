.social-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full screen height */
    background-color: black; /* Black background */
}

.social-link {
    color: #E1DEDB; /* White text */
    font-size: 48px; /* Big font size */
    margin: 20px 0; /* Spacing between links */
    text-decoration: none; /* No underline */
    letter-spacing: -3px;
}

.social-link:hover {
    text-decoration: underline; /* Underline on hover */
    cursor: pointer;
}
