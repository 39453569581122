html {
    /* Smooth scrolling for the entire page*/
    scroll-behavior: smooth;
    font-family: "Helvetica Neue";
    background-color: #000000;

}

/* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}