.navbar {
    width: 100%;
    color: #E1DEDB;
    position: fixed; /* Fix navbar to the top of the page */
    top: 0;
    left: 0;
    z-index: 1000; /* Ensures the navbar stays on top of other content */
    padding: 0 20px; /* Padding on the sides */
    letter-spacing: -1px;
    font-family: "Helvetica Neue";
    box-sizing: border-box; /* Ensure padding is included in the width */
    overflow: hidden; /* Prevent horizontal scrolling */

}

.nav-links {
    list-style: none; /* Removes bullet points from the list */
    padding: 0;
    margin: 0;
    display: flex; /* Display links in a row */
    justify-content: space-evenly; /* Space items evenly */
    align-items: center;
    height: 60px; /* Fixed height for the navbar */
    flex-wrap: wrap;
}

.nav-links li {
    margin: 0 10px; /* Space between links */
}

.nav-links button {
    color: #E1DEDB;
    text-decoration: none; /* Removes underline from links */
    font-size: 20px;
    transition: color 0.3s;
    background-color: transparent;
}

.nav-links button:hover {
    color: #ff0000; /* Highlight color when hovering over links */
}

/* Dropdown Styles */
.dropdown:hover .dropdown-content {
    display: block;
    /* center block under text */
}

.dropdown-content {

    display: none;
    position: absolute;
    background-color: transparent;
    min-width: 100px;
    box-shadow: 0px 8px 16px 0px rgba(253, 253, 253, 0.2);
    z-index: 1001; /* Higher than navbar z-index */
    white-space: nowrap;
}

.dropdown-content li {
    float: none;
    color: #ff0000;

}

.dropdown-content button {
    color: #E1DEDB;
    padding: 8px 10px;
    text-decoration: none;
    display: block;
    text-align: left;
    font-size: 12px;
    position: relative;

}


.dropdown-content button:hover {
    background-color: rgba(255, 255, 255, 0.1);
}



@media (max-width: 480px) {
    .nav-links button {
        font-size: 14px; /* Further reduce font size on very small screens */
    }

    .navbar {
        padding: 0px; /* Further reduce padding on extra small screens */
    }

    .nav-links li {
        margin: -10px; /* Reduce margins even more */
    }
}