.radio-container {
    display: flex;
    flex-direction: column;
    justify-content: center; /* Centers content vertically */
    align-items: center; /* Centers content horizontally */
    height: 100vh; /* Full viewport height */
    text-align: center;
    color: #E1DEDB;
    font-family: "Helvetica Neue", sans-serif;
}

.radio-container h1 {
    font-size: 48px;
    margin-bottom: 20px;
    letter-spacing: -3px;
}

.radio-container p {
    font-size: 20px;
    margin-bottom: 40px;
}

iframe {
    margin-top: 20px;
    border: none;
    border-radius: 12px;
    width: 100%;
    max-width: 600px; /* Optional: Set a max width for the iframe */
    height: 352px;
}

@media (max-width: 768px) {
    .radio-container {
        padding: 20px;
    }

    .radio-container h1 {
        font-size: 36px;

    }

    .radio-container p {
        font-size: 16px;
        padding: 10px;
    }
}